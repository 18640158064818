export const PASSWORD_FORM_COLUMNS = [
  {
    title: '原密码',
    dataIndex: 'pwd',
    formEditable: true,
    type: 'password',
    rules: [{ required: true, message: '请输入输入原密码' }],
  },
  {
    title: '新密码',
    dataIndex: 'pwdUpdated',
    formEditable: true,
    type: 'password',
    rules: [{ required: true, message: '请输入输入新密码' }],
  },
  {
    title: '输入新密码',
    dataIndex: 'confirmPassword',
    formEditable: true,
    type: 'password',
    dependencies: ['pwdUpdated'],
    rules: [
      { required: true, message: '请输入输入新密码' },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue('pwdUpdated') === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error('两次密码输入不一致!'));
        },
      }),
    ],
  },
];
