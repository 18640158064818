/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Input,
  Form,
  DatePicker,
  InputNumber,
  Image,
  Upload,
  Select,
  Switch,
} from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import xss from 'xss';
import { EditableContext } from '../editable-row';
import './index.less';

const whiteArr = ['style'];
const { TextArea } = Input;
const { Option } = Select;

const onIgnoreTagAttr = (tag, name, value, isWhiteAttr) => {
  if (whiteArr.indexOf(name) > -1) {
    // 通过内置的escapeAttrValue函数来对属性值进行转义
    // console.log(name, value);
    return `${name}="${xss.escapeAttrValue(value)}"`;
  }
  return false;
};

function EditableCell({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  type,
  ellipsis,
  valuePropName,
  selectList,
  rules,
  ellipsisRow,
  render,
  condition,
  formSort,
  ...restProps
}) {
  const [editing, setEditing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef(null);
  // const dateRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (type === 'image') {
      return;
    }
    setEditing(!editing);
    // form.setFieldsValue({
    //   [dataIndex]: record[dataIndex],
    // });
    if (!editing) {
      form.setFieldsValue(record);
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      console.log(record, values);
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
      toggleEdit();
    }
  };

  const handleOpenChange = async (open) => {
    if (!open) {
      setEditing(false);
    }
  };

  const handleChangeImage = (file) => {
    setUploading(true);
    setTimeout(() => {
      setUploading(false);
    }, 5 * 1000);
    console.log(file);
    return false;
  };

  let childNode = children;
  let cellNode = children;

  const FORM_ITEM_MAP = {
    input: <Input ref={inputRef} onPressEnter={save} onBlur={save} />,
    textarea: (
      <TextArea rows={2} ref={inputRef} onPressEnter={save} onBlur={save} />
    ),
    date: (
      <DatePicker
        showTime={{ format: 'HH:mm' }}
        onOk={save}
        format="YYYY-MM-DD HH:mm"
        onOpenChange={handleOpenChange}
        allowClear={false}
        open={editing}
      />
    ),
    inputNumber: (
      <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />
    ),
    select: (
      <Select
        style={{ width: '100%' }}
        onDropdownVisibleChange={handleOpenChange}
        open={editing}
        onChange={save}
      >
        {(selectList || []).map((item) => (
          <Option
            value={item.value}
            key={item.value}
            className="edit-cell-option"
          >
            {item.label}
          </Option>
        ))}
      </Select>
    ),
    switch: <Switch onChange={save} />,
  };

  if (type === 'image' && record[dataIndex] !== 'no-need') {
    cellNode = (
      <div onClick={(e) => e.stopPropagation()} className="image-cell-wrap">
        {(record[dataIndex] || []).map((item) => (
          <Image src={item.url} width="70%" key={item.url} />
        ))}

        <span className={`edit ${uploading ? 'uploading' : ''}`}>
          {uploading ? (
            <LoadingOutlined style={{ fontSize: '20px', marginLeft: '8px' }} />
          ) : (
            <Upload
              accept="image/*"
              beforeUpload={handleChangeImage}
              itemRender={() => ''}
            >
              <EditOutlined style={{ fontSize: '20px', marginLeft: '8px' }} />
            </Upload>
          )}
        </span>
      </div>
    );
  }

  // if (type === 'select') {
  //   formItemNode = (
  //     <Select
  //       style={{ width: '100%' }}
  //       onDropdownVisibleChange={handleOpenChange}
  //       open={editing}
  //       onChange={save}
  //     >
  //       {(selectList || []).map((item) => (
  //         <Option
  //           value={item.value}
  //           key={item.value}
  //           className="edit-cell-option"
  //         >
  //           {item.label}
  //         </Option>
  //       ))}
  //     </Select>
  //   );
  // }

  // if (type === 'switch') {
  //   formItemNode = <Switch onChange={save} />;
  // }

  if (editable) {
    if (record[dataIndex] === 'no-need') {
      childNode = '';
    } else if (record[`${dataIndex}Rich`] || type === 'editor') {
      childNode = (
        <div
          dangerouslySetInnerHTML={{
            __html: xss(record[dataIndex], {
              onIgnoreTagAttr,
            }),
          }}
        />
      );
    } else {
      childNode =
        editing || type === 'switch' ? (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
            valuePropName={valuePropName || 'value'}
            rules={rules}
          >
            {/* {formItemNode} */}
            {FORM_ITEM_MAP[type]}
          </Form.Item>
        ) : (
          <div
            style={
              ellipsisRow
                ? {
                    height: `${22.5 * ellipsisRow}px`,
                    WebkitLineClamp: ellipsisRow,
                  }
                : {}
            }
            className={`editable-cell-value-wrap ${
              ellipsis ? 'ellipsis-cell' : ''
            } ${type === 'image' ? 'editable-cell-no-hover' : ''} ${
              ellipsisRow ? 'ellipsis-row' : ''
            }`}
            onClick={toggleEdit}
          >
            {cellNode}
          </div>
        );
    }
  }

  return <td {...restProps}>{childNode}</td>;
}

export default EditableCell;
