import { post } from '@/utils/http';

export const login = (params) =>
  post({
    url: '/manage/channel/proxy/login',
    data: params,
  });

export const logout = (params) =>
  post({
    url: '/manage/channel/proxy/logout',
    data: params,
  });
