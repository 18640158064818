import axios from 'axios';
// import { publicIp } from "./apiIp";
// import { LOGIN } from "./apiURL";
import { message } from 'antd';
import * as Lockr from 'lockr';
import { getLockrValue } from '@/utils/commonUtils';

const instance = axios.create({
  // 创建axios实例，在这里可以设置请求的默认配置
  timeout: 10000, // 设置超时时间10s
});
// 文档中的统一设置post请求头。下面会说到post请求的几种'Content-Type'
instance.defaults.headers.post['Content-Type'] = 'application/json';

const httpCode = {
  // 这里我简单列出一些常见的http状态码信息，可以自己去调整配置
  400: '请求参数错误',
  401: '权限不足, 请重新登录',
  403: '服务器拒绝本次访问',
  404: '请求资源未找到',
  500: '内部服务器错误',
  501: '服务器不支持该请求中使用的方法',
  502: '网关错误',
  504: '网关超时',
};

let noMessage = false;

/** 添加请求拦截器 * */
instance.interceptors.request.use(
  (config) => {
    config.headers['remind-channel'] = getLockrValue('remindChannel') || '';
    noMessage = !!config.noMessage;
    return config;
  },
  (error) =>
    // 对请求错误做些什么
    Promise.reject(error)
);

/** 添加响应拦截器  * */
instance.interceptors.response.use(
  (response) => {
    if (response.headers['remind-channel']) {
      Lockr.set('remindChannel', {
        value: response.headers['remind-channel'],
        expires: 24 * 60 * 60 * 1000,
        startTime: new Date().getTime(),
      });
    }
    if (response.status === 200) {
      if (response.data?.code !== 200 && response.data?.code) {
        message.error(response.data.msg);
        if (response.data.data?.code === 1) {
          // 登录过期
          Lockr.rm('channel');
          Lockr.rm('remindChannel');
          window.location.href = '/login';
        }
      }
      return Promise.resolve(response.data);
    }
    message.error('响应超时');
    return Promise.reject(response.data.message);
  },
  (error) => {
    if (error.response) {
      // 根据请求失败的http状态码去给用户相应的提示
      if (!noMessage) {
        const tips =
          error.response.status in httpCode
            ? httpCode[error.response.status]
            : error.response.data.message;
        message.error(tips);
      }
      return Promise.reject(error.response);
    }
    if (!noMessage) {
      message.error('请求超时, 请刷新重试');
    }

    return Promise.reject(new Error('请求超时, 请刷新重试'));
  }
);

/* 统一封装get请求 */
export const get = ({ url, params, config = {} }) =>
  instance({
    method: 'get',
    url,
    params,
    ...config,
  })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      return { error };
    });

/* 统一封装post请求  */
export const post = ({ url, data, config = {} }) =>
  instance({
    method: 'post',
    url,
    data,
    ...config,
  })
    .then((response) => response)
    .catch((error) => {
      console.error(error);
      return { error };
    });
