import * as Lockr from 'lockr';

export const getOrderParams = (formData) => {
  const orders = [];
  let search;
  Object.keys(formData).forEach((key) => {
    if (key.indexOf('sort') > -1) {
      const value = formData[key];
      if (value !== '') {
        const valueArr = value.split('-');
        orders.push({
          field: valueArr[0],
          direction: valueArr[1],
        });
      }
    }

    if (key.indexOf('filter') > -1) {
      const value = formData[key];
      if (value !== '') {
        const valueArr = value.split('-');
        valueArr.forEach((item) => {
          const valArr = item.split('_');
          if (search) {
            // eslint-disable-next-line prefer-destructuring
            search[valArr[0]] = valArr[1];
          } else {
            search = {
              [valArr[0]]: valArr[1],
            };
          }
        });
      }
    }
    if (formData.search) {
      if (search) {
        // eslint-disable-next-line prefer-destructuring
        search[formData.searchType] = formData.search;
      } else {
        search = {
          [formData.searchType]: formData.search,
        };
      }
    }
  });
  return { orders, search };
};

export const getSearchParams = (formData) => {
  let search;
  Object.keys(formData).forEach((key) => {
    if (key.indexOf('filter') > -1) {
      const value = formData[key];
      if (value !== '') {
        const valueArr = value.split('-');
        valueArr.forEach((item) => {
          const valArr = item.split('_');
          if (search) {
            // eslint-disable-next-line prefer-destructuring
            search[valArr[0]] = valArr[1];
          } else {
            search = {
              [valArr[0]]: valArr[1],
            };
          }
        });
      }
    }
    if (formData.search) {
      if (search) {
        // eslint-disable-next-line prefer-destructuring
        search[formData.searchType] = formData.search;
      } else {
        search = {
          [formData.searchType]: formData.search,
        };
      }
    }
  });
  return search;
};

export const getPageUpdate = (tableProps, delLen) => {
  const {
    dataSource,
    pagination: { current, pageSize, total },
  } = tableProps;
  let currentPage = current;
  if (
    dataSource.length === delLen &&
    current > 1 &&
    current === Math.ceil(total / pageSize)
  ) {
    currentPage = current - 1;
  }
  return currentPage;
};

export const getValue = (data, dataIndex) => {
  let value = data[dataIndex];
  if (value === null || value === undefined) {
    value = '';
  }
  return value;
};

export function formatSecond(result) {
  const h = Math.floor(result / 3600);
  const m = Math.floor((result / 60) % 60);
  const s = Math.floor(result % 60);
  result = s > 9 ? s : `0${s}`;
  if (m > 0) {
    result = `${m > 9 ? m : `0${m}`}:${result}`;
  }
  if (h > 0) {
    result = `${h > 9 ? h : `0${h}`}:${result}`;
  }

  return result;
}

export const isVideo = (path) =>
  /\.(mp4|avi|wmv|mpg|mpeg|mov|rm|ram|swf|flv)/.test(path);

export const isImage = (path) =>
  /\.(xbm|tif|pjp|svgz|jpg|jpeg|ico|tiff|gif|svg|jfif|webp|png|bmp|pjpeg|avif)/.test(
    path
  );

export function getLockrValue(key) {
  let value = Lockr.get(key);

  if (value?.startTime) {
    if (new Date().getTime() - value.startTime > value.expires) {
      value = false;
      Lockr.rm(key);
    } else {
      value = value.value;
    }
  }

  return value;
}

export const getUrl = (data) => {
  let url = '';
  if (data.children) {
    url = getUrl(data.children[0]);
  } else {
    url = data.url;
  }
  return url;
};
