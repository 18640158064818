/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {
  Modal,
  Form,
  Row,
  Col,
  InputNumber,
  DatePicker,
  Input,
  Upload,
  Select,
  Switch,
  Button,
  message,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import ClipboardJS from 'clipboard';

// import 'braft-editor/dist/index.css';
// import 'braft-extensions/dist/color-picker.css';
// import BraftEditor from 'braft-editor';
// import ColorPicker from 'braft-extensions/dist/color-picker';
import { LabelSelect, Editor } from './components';

import './index.less';

const { Option } = Select;

// BraftEditor.use(
//   ColorPicker({
//     includeEditors: ['editor-with-color-picker'],
//     theme: 'light', // 支持dark和light两种主题，默认为dark
//   })
// );

function DetailModal({
  modalType = 'detail',
  columns,
  data = {},
  visible,
  onClose,
  onOk,
  title,
  inline,
  width,
  labelCol,
  wrapperCol,
  centered,
  onValuesChange = () => {},
}) {
  const [form] = Form.useForm();
  const init = useRef(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewTitle, setPreviewTitle] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handlePreview = (file) => {
    console.log(file);
    setPreviewVisible(true);
    setPreviewImage(file.url || file.thumbUrl);
  };

  const getFormItem = ({
    type,
    labelList = [],
    selectList = [],
    dataIndex,
    placeholder,
    // eslint-disable-next-line no-shadow
    title,
    detailEditable,
  }) => {
    const FORM_MAP = {
      inputNumber: (
        <InputNumber
          style={{ width: '100%' }}
          placeholder={placeholder || `请输入${title}`}
        />
      ),
      date: (
        <DatePicker
          showTime={{ format: 'HH:mm' }}
          format="YYYY-MM-DD HH:mm"
          allowClear={false}
        />
      ),
      input: <Input placeholder={placeholder || `请输入${title}`} />,
      image: (
        <Upload
          // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          showUploadList={{
            showPreviewIcon: true,
            showRemoveIcon: false,
          }}
          onPreview={handlePreview}
        >
          <EditOutlined />
        </Upload>
      ),
      textarea: (
        <Input.TextArea placeholder={placeholder || `请输入${title}`} />
      ),
      labelSelect: <LabelSelect labelList={labelList} />,
      select: (
        <Select placeholder={placeholder || `请选择${title}`}>
          {selectList.map((item) => (
            <Option value={item.value} key={item.value}>
              {item.label}
            </Option>
          ))}
        </Select>
      ),
      switch: <Switch />,
      editor: <Editor />,
      password: (
        <Input.Password
          placeholder={placeholder || `请输入${title}`}
          visibilityToggle={modalType === 'add'}
        />
      ),
    };
    const realType = data[`${dataIndex}Rich`] ? 'editor' : type;
    return FORM_MAP[realType];
  };

  const handleOk = async () => {
    const values = await form.validateFields();
    console.log(values);
    setConfirmLoading(true);
    if (onOk) {
      await onOk({ ...data, ...values });
    }
    setConfirmLoading(false);
  };

  useEffect(() => {
    if (visible) {
      console.log(data);
      form.setFieldsValue(data);
    } else if (init.current) {
      form.resetFields();
      const ele = document.querySelector('.ant-modal-body');
      ele.scrollTo(0, 0);
    }
    init.current = true;
  }, [visible]);

  useEffect(() => {
    const clipboardArr = [];
    columns.forEach((item) => {
      if (item.suffixType === 'copy') {
        const clipboard = new ClipboardJS(`#${item.dataIndex}-suffix`, {
          text(trigger) {
            return form.getFieldValue(item.dataIndex);
          },
        });

        clipboard.on('success', (e) => {
          message.success('复制成功！');
        });

        clipboard.on('error', (e) => {
          message.error('复制失败！');
        });
        clipboardArr.push(clipboard);
      }
    });
    return () => {
      clipboardArr.forEach((clipboard) => {
        clipboard.destroy();
      });
    };
  }, [columns]);

  const getValueFromEvent = (e) => {
    console.log('Upload event:', e);

    if (Array.isArray(e)) {
      return e;
    }

    if (e.fileList) {
      // return e && e.fileList;
      e.file.status = 'success';
      return e && [e.file];
    }

    return e && e.target.value;
  };

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={onClose}
      width={width || '800px'}
      centered={centered}
      bodyStyle={{
        maxHeight: '600px',
        overflow: 'auto',
      }}
      confirmLoading={confirmLoading}
    >
      <Form
        labelCol={{ span: labelCol || (inline ? 10 : 4) }}
        wrapperCol={{ span: wrapperCol || (inline ? 14 : 16) }}
        form={form}
        onValuesChange={onValuesChange}
      >
        <Row>
          {columns.map((item, index) => (
            <Col
              span={inline ? 10 : 24}
              offset={inline && index % 2 !== 0 ? 2 : 0}
              key={item.title}
              style={{ position: 'relative' }}
            >
              {(item.editable || item.formEditable) &&
              (modalType === 'add' ||
                (modalType === 'detail' && !item.detailNotEditable)) &&
              data[item.dataIndex] !== 'no-need' ? (
                <>
                  <Form.Item
                    label={item.title}
                    name={item.dataIndex}
                    rules={item.rules || []}
                    valuePropName={item.valuePropName || 'value'}
                    getValueFromEvent={
                      item.type === 'image' ? getValueFromEvent : undefined
                    }
                  >
                    {getFormItem(item)}
                  </Form.Item>
                  {item.suffix && item.suffixType === 'copy' && (
                    <Button
                      type="link"
                      className="form-item-suffix"
                      id={`${item.dataIndex}-suffix`}
                    >
                      {item.suffix}
                    </Button>
                  )}
                  {item.suffix && !item.suffixType && (
                    <span className="form-item-suffix suffix-label">
                      {item.suffix}
                    </span>
                  )}
                </>
              ) : (
                data[item.dataIndex] !== 'no-need' && (
                  <Form.Item label={item.title}>
                    <span>{data[item.dataIndex] || ''}</span>
                  </Form.Item>
                )
              )}
            </Col>
          ))}
        </Row>
      </Form>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={() => setPreviewVisible(false)}
      >
        <div style={{ textAlign: 'center' }}>
          <img alt="example" style={{ width: '90%' }} src={previewImage} />
        </div>
      </Modal>
    </Modal>
  );
}

export default DetailModal;
