/* eslint-disable react/jsx-filename-extension */
import { FundViewOutlined } from '@ant-design/icons';

export default [
  {
    name: '渠道代理后台',
    url: '/home',
    icon: <FundViewOutlined />,
  },
];
