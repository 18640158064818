/* eslint-disable react/jsx-filename-extension */
import { Tag } from 'antd';

export const COLUMNS = [
  {
    title: '提现时间',
    dataIndex: 'recordTime',
  },
  {
    title: '提现类型',
    dataIndex: 'desc',
    width: 100,
    render: (text) =>
      text === '金币返佣' ? (
        <Tag color="green">金币返佣</Tag>
      ) : (
        <Tag color="orange">会员返佣</Tag>
      ),
  },
  {
    title: '提现金额',
    dataIndex: 'number',
    width: 120,
    render: (text) => (text ? `${text}元` : '无'),
  },
];

export const CASH_FORM_COLUMNS = [
  {
    title: '输入提现金额（申请提交后，将自动从余额中扣除）',
    dataIndex: 'withdrawQuota',
    formEditable: true,
    type: 'inputNumber',
    rules: [{ required: true, message: '请输入输入提现金额' }],
  },
  {
    title: '输入提现银行卡号',
    dataIndex: 'withdrawCard',
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入提现银行卡号' }],
  },
  {
    title: '确认提现账户名',
    dataIndex: 'withdrawUserName',
    formEditable: true,
    type: 'input',
    rules: [{ required: true, message: '请输入提现账户名' }],
  },
];

const STATE_MAP = {
  0: '申请中',
  1: '已处理',
  2: '已驳回',
};

export const WITHDRAWALS_COLUMNS = [
  {
    title: '提现时间',
    dataIndex: 'recordTime',
    width: 140,
    align: 'center',
  },
  {
    title: '申请提现金额',
    dataIndex: 'account',
    width: 110,
    align: 'center',
  },
  {
    title: '提现账户',
    dataIndex: 'withdrawCard',
    width: 150,
    align: 'center',
  },
  {
    title: '提现人',
    dataIndex: 'withdrawUserName',
    width: 80,
    align: 'center',
  },
  {
    title: '状态',
    dataIndex: 'state',
    width: 80,
    align: 'center',
    render: (text) => STATE_MAP[text],
  },
  {
    title: '提现反馈',
    dataIndex: 'desc',
    align: 'center',
  },
];
