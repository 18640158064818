import { Button, Form, Input, message } from 'antd';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as Lockr from 'lockr';
import { SliderVertify } from '@/components';
import { login } from '@/services/login';

import './index.less';

function Login({ onLogin }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const rules = useRef({
    username: [{ required: true, message: '请输入渠道账号' }],
    password: [{ required: true, message: '请输入密码' }],
  });
  const onFinish = async (values) => {
    const result = await login(values);

    if (result.code === 200) {
      message.success('登陆成功');
      Lockr.set('channel', {
        value: result.data,
        expires: 24 * 60 * 60 * 1000,
        startTime: new Date().getTime(),
      });
      onLogin();
    }
  };
  return (
    <div className="login-wrap">
      <div className="login-content">
        <Form
          wrapperCol={{ span: 24 }}
          form={form}
          onFinish={onFinish}
          name="login-form"
          validateTrigger={['onBlur', 'onChange']}
        >
          <Form.Item name="username" rules={rules.current.username}>
            <Input
              prefix={<UserOutlined />}
              size="large"
              placeholder="渠道账号"
            />
          </Form.Item>
          <Form.Item name="pwd" rules={rules.current.password}>
            <Input.Password
              size="large"
              prefix={<LockOutlined />}
              placeholder="密码"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" size="large" block>
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default Login;
