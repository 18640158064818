import { Modal, Table } from 'antd';
import { useEffect } from 'react';
import { useAntdTable } from 'ahooks';
import { withdrawRecord } from '@/services/home';

const getTableData = async ({ current, pageSize }) => {
  const result = await withdrawRecord({
    page: current,
    size: pageSize,
  });

  const data = result.data || {};
  return {
    total: data.total || 0,
    list: data.list || [],
  };
};

function WithdrawalsRecord({ columns, visible, onClose }) {
  const { tableProps, run } = useAntdTable(getTableData, {
    defaultParams: [{ current: 1, pageSize: 10 }],
    manual: true,
  });

  useEffect(() => {
    if (visible) {
      run({ current: 1, pageSize: 10 });
    }
  }, [visible]);

  return (
    <Modal
      title="提现记录"
      width={1000}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
    >
      <Table
        columns={columns}
        bordered
        size="small"
        {...tableProps}
        pagination={{
          ...tableProps.pagination,
          showTotal: (total) => `共 ${total} 条`,
        }}
        rowKey="recordTime"
      />
    </Modal>
  );
}

export default WithdrawalsRecord;
