import { Space, Tag } from 'antd';

function LabelSelect({ value = [], onChange, labelList = [] }) {
  const handleSelect = ({ value: val, isAll }) => {
    let newValue = [...value];
    if (isAll) {
      newValue = [val];
    } else {
      const allIndex = newValue.indexOf('all');
      if (allIndex > -1) {
        newValue.splice(allIndex, 1);
      }
      const index = newValue.indexOf(val);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(val);
      }
    }

    if (!newValue.length) {
      const allItem = labelList.find((item) => item.isAll);
      newValue = allItem ? [allItem.value] : [];
    }

    onChange(newValue);
  };

  return (
    <div className="label-wrap">
      <Space wrap>
        {labelList.map((item) => (
          <div
            key={item.value}
            style={{ cursor: 'pointer' }}
            onClick={() => handleSelect(item)}
          >
            <Tag
              color={value.indexOf(item.value) > -1 ? 'processing' : 'default'}
            >
              {item.label}
            </Tag>
          </div>

          // <Button
          //   size="small"
          //   key={item.value}
          //   type="primary"
          //   ghost
          //   onClick={() => handleSelect(item.value)}
          // >
          //   {item.label}
          // </Button>
        ))}
        {/* <Button size="small">不限标签</Button>
        <Button size="small">不限标签</Button>
        <Button size="small">不限标签</Button>
        <Button size="small">不限标签</Button> */}
      </Space>
    </div>
  );
}

export default LabelSelect;
