// import logo from './logo.svg';
import { useState, useEffect } from 'react';
import { Button } from 'antd';
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import * as Lockr from 'lockr';

import Login from '@/pages/login';
import Home from '@/pages/home';
import { MainLayout } from '@/components';

import menuData from '@/config/menuData';
import { getLockrValue } from '@/utils/commonUtils';

import './App.less';
import './reset-antd.less';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIslogin] = useState(!!getLockrValue('channel'));
  useEffect(() => {
    if (!isLogin) {
      Lockr.rm('channel');
      Lockr.rm('remindChannel');
      navigate('/login');
    } else if (location.pathname === '/login') {
      navigate('/');
    }
  }, [isLogin]);
  return (
    <div className="App">
      <Routes>
        {isLogin && (
          <Route
            path="/"
            element={
              <MainLayout
                menuData={menuData}
                onLogout={() => setIslogin(false)}
              />
            }
          >
            <Route path="home" element={<Home />} />
          </Route>
        )}

        <Route
          path="login"
          element={<Login onLogin={() => setIslogin(true)} />}
        />
      </Routes>
    </div>
  );
}

export default App;
