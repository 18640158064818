import { Layout, Menu, Dropdown, Button, Modal, message } from 'antd';
import { Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect, useRef, useState } from 'react';
import DetailModal from '../detail-modal';
import { PASSWORD_FORM_COLUMNS } from './config';
import { useDetailModal } from '@/hooks';
import { getLockrValue } from '@/utils/commonUtils';
import { logout } from '@/services/login';

import './index.less';
import logo from '../../images/web-logo.png';
import userIcon from '../../images/user-icon.png';
import { updatePwd } from '@/services/home';

const { Header, Content, Sider } = Layout;
const { confirm } = Modal;

const getFirstActiveKey = (menuData, pathname) => {
  let activeMenu;
  let activeCurrentMenu; // 具体某个菜单
  const getActiveMenu = (data, parentMenu) => {
    data.forEach((item) => {
      if (activeMenu) {
        return;
      }
      if (item.url === pathname) {
        activeMenu = parentMenu || item;
        activeCurrentMenu = item;
      } else if (item.children) {
        getActiveMenu(item.children, parentMenu || item);
      }
    });
  };
  getActiveMenu(menuData);
  return { activeMenu, activeCurrentMenu };
};

function MainLayout({ menuData, onLogout }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { showDetail, handleShowDetail, handleCloseDetail } = useDetailModal();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(menuData[0].url);
    }
  }, []);

  const handleLogout = () => {
    confirm({
      title: '确定要退出吗?',
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const result = await logout({});
        if (result.code === 200) {
          onLogout();
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleUpdatePwd = async (val) => {
    delete val.confirmPassword;
    const result = await updatePwd(val);
    if (result.code === 200) {
      message.success('密码修改成功');
      handleCloseDetail();
      const result = await logout({});
      if (result.code === 200) {
        onLogout();
      }
    }
  };

  return (
    <Layout className="main-layout">
      <Header className="header">
        <div className="left-wrap">
          <div className="logo">
            {/* <img src={logo} alt="logo" /> */}
            渠道代理后台
          </div>
        </div>
        <div>
          <span style={{ color: '#fff' }}>
            渠道id: {getLockrValue('channel')?.channelId}
          </span>
          <Button type="link" onClick={handleShowDetail}>
            修改密码
          </Button>
          <Button type="link" onClick={handleLogout}>
            登出
          </Button>
        </div>
      </Header>
      <Layout>
        <Layout style={{ overflow: 'auto' }}>
          <Content className="site-layout-background">
            {/* <div className="content-wrap"> */}
            <Outlet />
            {/* </div> */}
          </Content>
        </Layout>
      </Layout>
      <DetailModal
        width={500}
        columns={PASSWORD_FORM_COLUMNS}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        onOk={handleUpdatePwd}
        title="修改密码"
        centered
        labelCol={24}
        wrapperCol={24}
      />
    </Layout>
  );
}

export default MainLayout;
