import { post } from '@/utils/http';

export const updatePwd = (params) =>
  post({
    url: '/manage/channel/proxy/updatePwd',
    data: params,
  });

export const getChannelInfo = (params) =>
  post({
    url: '/manage/channel/proxy/info',
    data: params,
  });
export const withdraw = (params) =>
  post({
    url: '/manage/channel/proxy/withdraw',
    data: params,
  });
export const withdrawRecord = (params) =>
  post({
    url: '/manage/channel/proxy/withdrawRecord',
    data: params,
  });
export const commissionRecord = (params) =>
  post({
    url: '/manage/channel/proxy/commissionRecord',
    data: params,
  });
