import { Button, Card, Tag, Row, Col, Space, Table, message } from 'antd';
import { useState, useEffect } from 'react';
import ClipboardJS from 'clipboard';
import { useRequest, useAntdTable } from 'ahooks';
import { COLUMNS, CASH_FORM_COLUMNS, WITHDRAWALS_COLUMNS } from './config';
import { usePagination, useDetailModal } from '@/hooks';
import { DetailModal } from '@/components';
import { WithdrawalsRecord } from './components';

import { getChannelInfo, commissionRecord, withdraw } from '@/services/home';

import './index.less';

const getInfo = async () => {
  const result = await getChannelInfo({});

  return result.data || {};
};

const getCommissionRecord = async ({ current, pageSize }) => {
  const result = await commissionRecord({
    page: current,
    size: pageSize,
  });

  const data = result.data || {};

  return {
    total: data.total || 0,
    list: data.list || [],
  };
};

function Home() {
  const { data: channelInfo, run: getChannelInfo } = useRequest(getInfo);
  const { tableProps } = useAntdTable(getCommissionRecord, {
    defaultParams: [{ current: 1, pageSize: 10 }],
  });

  const { showDetail, handleShowDetail, handleCloseDetail } = useDetailModal();
  const {
    showDetail: showWithdrawals,
    handleShowDetail: handleShowWithdrawals,
    handleCloseDetail: closeWithdrawals,
  } = useDetailModal();

  useEffect(() => {
    const clipboard = new ClipboardJS(`#copy-btn`, {
      text(trigger) {
        return channelInfo.url;
      },
    });

    clipboard.on('success', (e) => {
      message.success('复制成功！');
    });

    clipboard.on('error', (e) => {
      message.error('复制失败！');
    });
    return () => {
      clipboard.destroy();
    };
  }, [channelInfo]);

  const hanldeWithdraw = async (val) => {
    const result = await withdraw(val);
    if (result.code === 200) {
      message.success('提现申请已提交');
      handleCloseDetail();
      getChannelInfo();
    }
  };

  return (
    <div className="channel-home">
      <Card className="channel-derail-content">
        <div className="channel-url-wrap">
          <span className="left-wrap">
            <span className="link">{channelInfo?.url}</span>
            <span className="update-text">
              该地址为{channelInfo?.urlUpdate}日前更新
            </span>
          </span>
          <Button type="primary" id="copy-btn">
            复制我的推广链接
          </Button>
        </div>
        <Tag color="warning">
          推广链接有可能被大陆封杀，为保证您的推广效率，请留意并使用最新的推广地址。新地址邀请的用户所产生的充值，依然会分佣到您的账户中
        </Tag>
        <Row className="today-data" justify="center">
          <Col span={6} className="data-item">
            <div className="title">今日新增人数</div>
            <div className="value">{channelInfo?.addNumber}</div>
          </Col>
          <Col span={6} className="data-item">
            <div className="title">今日新增分佣（元）</div>
            <div className="value">{channelInfo?.addCommission}</div>
          </Col>
          <Col span={6} className="data-item">
            <div className="title">今日分佣笔数</div>
            <div className="value">{channelInfo?.commissionNumber}</div>
          </Col>
          {/* <Col span={4} className="data-item">
            <div className="title">今日新增付费率</div>
            <div className="value">{channelInfo?.addPayPercent}</div>
          </Col> */}
          {!!channelInfo?.memberPercent && (
            <Col span={6} className="data-item">
              <div className="title">我的会员分佣比例</div>
              <div className="value">{channelInfo?.memberPercent}</div>
            </Col>
          )}
          {/* {!!channelInfo?.coinPercent && (
            <Col span={4} className="data-item">
              <div className="title">我的金币分佣比例</div>
              <div className="value">{channelInfo?.coinPercent}</div>
            </Col>
          )} */}
        </Row>
        <Row className="week-data" gutter={20}>
          <Col span={6}>
            <Tag color="success">7日新增 {channelInfo?.addDayNumber}人</Tag>
          </Col>
          <Col span={6}>
            <Tag color="success">
              7日新增分佣 {channelInfo?.addDayCommission}元
            </Tag>
          </Col>
          <Col span={6}>
            <Tag color="processing">历史邀请 {channelInfo?.proxyNumber}人</Tag>
          </Col>
          <Col span={6}>
            <Tag color="processing">
              历史分佣 {channelInfo?.proxyCommission}元
            </Tag>
          </Col>
        </Row>
        <div className="channel-url-wrap">
          <span className="cash">账户余额:{channelInfo?.account}元</span>
          <Space>
            <Button type="primary" onClick={handleShowDetail}>
              提现
            </Button>
            <Button type="primary" onClick={handleShowWithdrawals}>
              提现记录
            </Button>
          </Space>
        </div>
        <Card className="list-wrap">
          <div className="title">分佣明细</div>
          <Table
            columns={COLUMNS}
            showHeader={false}
            size="small"
            {...tableProps}
            pagination={{
              ...tableProps.pagination,
              showTotal: (total) => `共 ${total} 条`,
            }}
            rowKey="recordTime"
          />
        </Card>
      </Card>
      <DetailModal
        width={500}
        columns={CASH_FORM_COLUMNS}
        destroyOnClose
        visible={showDetail}
        onClose={handleCloseDetail}
        title="申请提现"
        centered
        labelCol={24}
        wrapperCol={24}
        onOk={hanldeWithdraw}
      />
      <WithdrawalsRecord
        columns={WITHDRAWALS_COLUMNS}
        visible={showWithdrawals}
        onClose={closeWithdrawals}
        centered
      />
    </div>
  );
}

export default Home;

// function ChannelPage() {
//   return (
//     <div className="channel-page-wrap">
//       <ChannelDetail />
//     </div>
//   );
// }

// export default ChannelPage;
